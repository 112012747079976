
var GoUrl='/downloading';
const { getQueryString } = require('@fe/common/lib/utils.js');;

// 12345 youtube， 详情页、下载页 点击 悬浮的 “open” 按钮
$('#fixedDownloadBtn').click(function(){
    let downloadUrl = $('.andoridModal').attr("data-download-url");
    if (window.isFreeGetVideo) {
        const _subpub = getQueryString('subpub', location.search);
        if (_subpub) {
          downloadUrl = downloadUrl + `&subpub=${_subpub}`;
        }
        GoUrl = downloadUrl;
        $('.andoridModal').css({display:'block'}) 
    } else {
        GoUrl = downloadUrl;
        window.setTimeout(function(){  
            $('.andoridModal').css({display:'block'}) 
        },1200);   
    }
});
function hiddenModal(){ 
    $('.andoridModal').css({display:'none'}) 
    $('#my-player_html5_api').css({display:'block'})
}
$('.andoridModal').click(function(){
    hiddenModal()
})
$("#nativeAlert-cancel").click(function(){
    hiddenModal()
    let _this=this
    $(_this).css({background:'#F2F2F2'})
    setTimeout(function () {
        $(_this).css({background:'#FFF'})
    }, 1000);
})
$("#nativeAlert-ok").click(function(){
    let _this=this
    $(_this).css({background:'#F2F2F2'})
    hiddenModal() 
    if (window.isFreeGetVideo) {
        gtag('event', 'conversion', {'send_to': 'AW-673133402/87n2CKy669wBENrm_MAC'});
    }
    window.location.href=GoUrl
    return; 
})